import { graphql } from 'gatsby';
import { withLang } from '../../../utils/reactTools';
import { CaseStudies } from '../../case-studies';

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: {regex: "/misc/seo.md/"}) {
      fields {
        locales {
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/pages/case-studies.md/"}) {
      fields {
        locales {
          fr {
            pageTheme
            ...SeoFragmentFR
            ...heroBannerFragmentFR
          }
          en {
            heroBanner {
              text
            }
          }
        }
      }
    }
    allCaseStudies: allMarkdownRemark(filter: {frontmatter: {type: {eq: "case-study"}}}) {
      nodes {
        fields {
          locales {
            fr {
              title
              category
              weight
              showInListing
              client
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 2716
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                    transformOptions: {fit: COVER}
                  )
                } 
              }
              smallDescription
            }
          }
        }
      }
    }
  }
`;

export default withLang(CaseStudies, 'fr');
